var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col h-full" }, [
    _vm.error
      ? _c(
          "button",
          {
            staticClass:
              "relative px-4 py-3 text-red-700 bg-red-100 border border-red-400 rounded",
            attrs: { role: "alert" },
            on: {
              click: function($event) {
                _vm.error = ""
              }
            }
          },
          [
            _c(
              "span",
              {
                staticClass: "block sm:inline",
                attrs: { "data-cy": "error-msg" }
              },
              [_vm._v(_vm._s(_vm.error))]
            )
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flex items-center justify-center h-full pb-32 mt-32" },
      [
        _c(
          "login-form-component",
          {
            staticClass: "w-2/3 p-8 border border-gray-500 lg:w-1/2 xl:w-1/3",
            on: {
              "login-selected": _vm.onLogin,
              "update-password": _vm.onUpdatePassword,
              "update-email": _vm.onUpdateEmail
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "flex items-center justify-end space-x-8",
                attrs: { slot: "loginButton" },
                slot: "loginButton"
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "forgotPassword" } } },
                  [
                    _c("a", { staticClass: "text-black" }, [
                      _vm._v("I can't sign in")
                    ])
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "px-4 py-2 text-white bg-black border-2 border-black border-none rounded disabled:bg-gray-400 font-roboto",
                    attrs: {
                      type: "button",
                      disabled: _vm.email == "" || _vm.password == "",
                      "data-cy": "login-button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.login({
                          email: _vm.email,
                          password: _vm.password
                        })
                      }
                    }
                  },
                  [_vm._v(" Log in ")]
                ),
                _vm.showSpinner && !_vm.isLoggedIn
                  ? _c("div", {
                      staticClass:
                        "w-12 h-12 border-4 border-blue-500 rounded-full loader animate-spin"
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }