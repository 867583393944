































































import { Component, Prop, Vue } from 'vue-property-decorator';

export interface LoginFormFields {
  email: string;
  password: string;
}

@Component<LoginFormComponent>({})
export default class LoginFormComponent extends Vue {
  private email = '';
  private password = '';

  @Prop({ default: '' })
  private error!: string;

  @Prop({ default: false })
  private showSpinner = false;

  private login() {
    this.$emit('login-selected', {
      email: this.email,
      password: this.password
    });
  }
}
