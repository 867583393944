







































import { Component, Vue } from 'vue-property-decorator';
import LoginFormComponent from '@/components/login/LoginFormComponent.vue';
import { LoginFormFields } from '@/components/login/LoginFormComponent.vue';
import { AuthClient } from '@/services/auth';

@Component<LoginPage>({
  components: {
    LoginFormComponent
  }
})
export default class LoginPage extends Vue {
  private showSpinner = false;
  private error = '';
  private email = '';
  private password = '';

  private async mounted() {
    if (this.$route.query.refresh === 'true') {
      await AuthClient.getInstance().signOut();
    }
  }

  get isLoggedIn() {
    const loggedIn =
      this.$store.getters['userModule/currentUser'] &&
      this.$store.getters['userModule/isLoggedIn'];

    if (loggedIn) {
      this.$router.replace({
        query: {
          redirect: 'home'
        }
      });
      this.showSpinner = false;
    }
    return loggedIn;
  }

  private async onUpdateEmail(email: string) {
    this.email = email;
  }

  private async onUpdatePassword(password: string) {
    this.password = password;
  }

  private async onLogin(loginFormFields: LoginFormFields) {
    await this.login(loginFormFields);
  }

  private async login(loginFormFields: LoginFormFields) {
    this.showSpinner = true;
    await AuthClient.getInstance().signOut();
    this.error = '';
    try {
      await AuthClient.getInstance().signIn(
        loginFormFields.email,
        loginFormFields.password
      );
    } catch (err) {
      this.error = err;
    }
  }
}
