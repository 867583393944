var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "items-center justify-items-center" }, [
    _c(
      "form",
      {
        staticClass: "flex flex-col justify-start space-y-4 rounded login-form"
      },
      [
        _vm.error
          ? _c(
              "button",
              {
                staticClass:
                  "relative px-4 py-3 text-red-700 bg-red-100 border border-red-400 rounded",
                attrs: { role: "alert" },
                on: {
                  click: function($event) {
                    _vm.error = ""
                  }
                }
              },
              [
                _c("span", { staticClass: "block sm:inline" }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "flex" }, [
          _c("img", {
            staticClass: "inline-block invert-background",
            attrs: { src: require("../../../public/skyhub_mark.svg"), alt: "" }
          }),
          _c(
            "div",
            { staticClass: "flex py-6 ml-4 mr-6" },
            [
              _c("router-link", { attrs: { to: "/", active: false } }, [
                _c("span", { staticClass: "text-2xl tracking-tightoverpass" }, [
                  _vm._v("Skyhub")
                ])
              ])
            ],
            1
          )
        ]),
        _c(
          "label",
          {
            staticClass: "block text-sm text-gray-500 uppercase",
            attrs: { for: "email" }
          },
          [_vm._v(" login email ")]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          staticClass: "skyhub-input-field",
          attrs: {
            id: "email",
            type: "text",
            "data-cy": "email",
            autocomplete: "email"
          },
          domProps: { value: _vm.email },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              },
              function($event) {
                return _vm.$emit("update-email", _vm.email)
              }
            ]
          }
        }),
        _c("div", {}, [
          _c(
            "label",
            {
              staticClass: "block text-sm text-gray-500 uppercase",
              attrs: { for: "password" }
            },
            [_vm._v(" password ")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password"
              }
            ],
            staticClass: "skyhub-input-field",
            attrs: {
              id: "password",
              type: "password",
              "data-cy": "password",
              autocomplete: "current-password"
            },
            domProps: { value: _vm.password },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                },
                function($event) {
                  return _vm.$emit("update-password", _vm.password)
                }
              ]
            }
          })
        ]),
        this.showSpinner && !_vm.isLoggedIn
          ? _c("div", { staticClass: "flex items-center justify-between" }, [
              _c("div", {
                staticClass:
                  "border-4 border-blue-500 rounded-full loader animate-spin"
              })
            ])
          : _vm._e(),
        _vm._t("loginButton"),
        _c("router-link", { attrs: { to: { name: "signup" } } }, [
          _vm._v("Create an account")
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }